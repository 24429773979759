const selectTheme = sectionTheme =>
  ({
    dark: {
      description: {
        color: 'neutral.0',
      },
      preTitle: { color: 'neutral.0' },
      section: { backgroundColor: 'neutral.90' },
      title: { color: 'neutral.0' },
    },
    white: {
      description: {
        color: 'neutral.80',
      },
      preTitle: { color: 'neutral.60' },
      section: { backgroundColor: 'neutral.0' },
      title: { color: 'neutral.90' },
    },
    gray: {
      description: {
        color: 'neutral.80',
      },
      preTitle: { color: 'neutral.60' },
      section: { backgroundColor: 'neutral.10' },
      title: { color: 'neutral.90' },
    },
  }[sectionTheme || 'white'])

export { selectTheme }
