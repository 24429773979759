import { node } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: node,
}

const Title = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography
      variant={{ _: 'exceptionSmBold', '5xl': 'exceptionMdBold' }}
      {...others}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = propTypes

export default Title
