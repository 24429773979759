import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Typography = styled(RichText)`
  margin-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('3xl')} {
    margin-bottom: ${selectTheme('spacingBottom.12xl')};
  }
`
