import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  padding: ${selectTheme('spacingY.6xl')} ${selectTheme('spacingX.6xl')};
  border-radius: ${selectTheme('radius.2xl')};

  background-color: ${selectTheme('colors.neutral.0')};
  box-shadow: ${selectTheme('shadow.sm')};
`

export const Description = styled(RichText)`
  margin-bottom: ${selectTheme('spacingBottom.6xl')};
`

export const Footer = styled.div``

export const Name = styled(RichText)`
  margin-bottom: ${selectTheme('spacingBottom.sm')};
`

export const Location = styled(RichText)``

export const QuoteMark = styled.img`
  margin-bottom: ${selectTheme('spacingBottom.16xl')};
  width: 28px;
  height: 24px;
`
