import { string } from 'prop-types'

import quoteIcon from './assets/quotes.svg'

import {
  Container,
  Description,
  Footer,
  Location,
  Name,
  QuoteMark,
} from './styles'

const propTypes = {
  description: string,
  location: string,
  name: string,
}

const Card = ({ description, location, name, ...others }) => {
  return (
    <Container {...others}>
      <QuoteMark src={quoteIcon} />
      <Description variant="bodySmRegular" data-testid="description">
        {description}
      </Description>

      <Footer>
        <Name variant="bodyMdBold" data-testid="name">
          {name}
        </Name>

        <Location variant="bodyXsRegular" data-testid="location">
          {location}
        </Location>
      </Footer>
    </Container>
  )
}

Card.propTypes = propTypes

export default Card
