import { node } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: node,
}

const SubTitle = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography data-testid="description" variant="bodyLgRegular" {...others}>
      {children}
    </Typography>
  )
}

SubTitle.propTypes = propTypes

export default SubTitle
