import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const InfoContainer = styled.div`
  ${media.up('3xl')} {
    max-width: 496px;
  }
`

export default InfoContainer
