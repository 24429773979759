import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import Card from './components/Card'

const listStyles = ({ listLength }) => ({
  flexible: css`
    grid-template-columns: repeat(${listLength}, minmax(236px, 1fr)) 1px;

    ${media.up('3xl')} {
      grid-template-columns: repeat(${listLength}, 1fr) 1px;
    }
  `,
  fixedWidth: css`
    grid-template-columns: repeat(2, minmax(236px, 392px)) 1px;

    ${media.up('3xl')} {
      grid-template-columns: repeat(2, minmax(392px, 392px)) 1px;
    }
  `,
})

const List = styled.ul(
  ({ listLength = 1, theme }) => css`
    list-style: none;
    display: grid;
    grid-gap: ${selectTheme('spacingX.3xl')({ theme })};
    ${listStyles({ listLength })[listLength === 2 ? 'fixedWidth' : 'flexible']}
    grid-template-rows: max-content;
    overflow-x: auto;
    padding: 18px 26px 18px 18px;
    margin: -18px;

    ${media.up('3xl')} {
      grid-gap: ${selectTheme('spacingX.6xl')({ theme })};
    }

    ${media.down('3xl')} {
      &::after {
        content: '';
        width: 1px;
        height: 1px;
      }
    }
  `,
)

const ListItem = styled(Card)``

export { ListItem, List }
