import { array, oneOf, string } from 'prop-types'

import { selectTheme } from './base.styles'
import CardList from './components/CardList'
import Description from './components/Description'
import InfoContainer from './components/InfoContainer'
import PreTitle from './components/PreTitle'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  backgroundColor: oneOf(['white', 'gray', 'light']),
  cards: array,
  description: string,
  preTitle: string,
  preTitleHeadingTag: string,
  title: string,
  titleHeadingTag: string,
}

const defaultProps = {
  preTitleHeadingTag: 'h1',
  titleHeadingTag: 'h2',
  cards: [],
}

const SocialProof = ({
  backgroundColor,
  cards,
  description,
  preTitle,
  preTitleHeadingTag,
  title,
  titleHeadingTag,
  ...others
}) => {
  const theme = selectTheme(backgroundColor)
  const titleAs = titleHeadingTag ?? defaultProps.titleHeadingTag
  const preTitleAs = preTitleHeadingTag ?? defaultProps.preTitleHeadingTag

  return (
    <Wrapper bgColor={theme.section.backgroundColor} {...others}>
      <InfoContainer>
        <PreTitle as={preTitleAs} color={theme.preTitle.color}>
          {preTitle}
        </PreTitle>
        <Title as={titleAs} color={theme.title.color}>
          {title}
        </Title>
        <Description color={theme.description.color}>{description}</Description>
      </InfoContainer>
      <CardList items={cards} />
    </Wrapper>
  )
}

SocialProof.propTypes = propTypes

export default SocialProof
