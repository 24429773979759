import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Section = styled.section(
  ({ bgColor, theme }) => css`
    background-color: ${selectTheme(`colors.${bgColor}`)({ theme })};

    padding: ${selectTheme('spacingY.6xl')({ theme })} 0
      ${selectTheme('spacingY.17xl')({ theme })};

    ${media.up('3xl')} {
      padding: ${selectTheme('spacingY.21xl')({ theme })} 0
        ${selectTheme('spacingY.23xl')({ theme })};
    }
  `,
)
