import { useEffect, useRef } from 'react'
import { arrayOf, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { List, ListItem } from './styles'

const propTypes = {
  items: arrayOf(
    shape({
      description: string.isRequired,
      name: string,
      location: string,
    }),
  ),
}

const CardList = ({ items }) => {
  const date = new Date().getTime()
  const listId = `card-list-${date}`
  const elementIds = items?.map((_, index) => `${index}-${date}`)
  const observer = useRef(null)
  const { triggerEvent } = useTriggerEvents()

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }
    const root = document.querySelector(`[id=${listId}]`)
    observer.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const { intersectionRatio, isIntersecting } = entry
          if (isIntersecting === true || intersectionRatio > 0.5) {
            const index = Number(entry.target.getAttribute('data-index')) || 1

            const cardsPercentage = 100 / elementIds.length
            const percentage = index * cardsPercentage + cardsPercentage

            triggerEvent({
              label: `${percentage}%`,
              pageInteraction: true,
            })

            observer.current?.unobserve(entry.target)
          }
        })
      },
      {
        root,
        rootMargin: '0px 0px 0px 0px',
        threshold: 0.5,
      },
    )

    const { current: currentObserver } = observer

    root?.childNodes.forEach(element => currentObserver.observe(element))

    return () => observer.current.disconnect()
  }, [elementIds, observer, listId, triggerEvent])

  return (
    <List listLength={items?.length || 0} id={listId}>
      {items?.map((item, index) => (
        <ListItem
          as="li"
          index={index}
          {...item}
          key={`card-${item.id}`}
          id={elementIds[index]}
          data-index={index}
          data-testid={`card-${item.id}`}
        />
      ))}
    </List>
  )
}

CardList.propTypes = propTypes

export default CardList
